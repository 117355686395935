<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Pago a Proveedores</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Detalle Pagos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>DETALLE PAGO A PROVEEDOR</strong></h4>
    </div>
    <div class="grid border-bottom-2" style="background-color: #f8f9fa">
      <div class="col-6">
        <p class="mb-0">
          <strong>RECIBO/FACTURA NRO: </strong>
          <span>{{ pago_proveedor.nro_recibo }}</span>
        </p>
        <p class="mb-0">
          <strong>MONTO A PAGAR: </strong>
          <span>{{
            convertirNumeroGermanicFormat(pago_proveedor.monto_total)
          }}</span>
        </p>
      </div>
      <div class="col-6">
        <p class="mb-0">
          <strong>FECHA DE RECEPCION: </strong>
          <span>{{ pago_proveedor.fecha_pago }}</span>
        </p>
        <p class="mb-0">
          <strong>TOTAL PAGADO: </strong>
          <span>{{ total_pagado }}</span>
        </p>
        <p class="mb-0">
          <strong>SALDO: </strong>
          <span>{{ saldo_pago }}</span>
        </p>
      </div>
    </div>
    <div class="col-12 mt-4">
      <DataTable ref="dt" :value="detalle_pagos" key="id">
        <Column field="oc_recepcion_id" header="OC RECEPCIÓN" />
        <Column field="nombre_usuario" header="USUARIO" />
        <Column field="fecha_pago" header="FECHA PAGO" />
        <Column field="monto" header="EN EFECTIVO" />
        <Column field="a_cuenta_metodo_pago" header="TRANSFERENCIAS">
          <template #body="slotProps">
            <span
              >{{ slotProps.data.a_cuenta_metodo_pago }} -
              {{ slotProps.data.nombre_metodo_pago }}</span
            >
          </template>
        </Column>
        <Column field="observacion" header="OBSERVACIÓN" />
      </DataTable>
    </div>
    <div class="flex col-12 justify-content-end">
      <Button @click="goBack" class="mr-2 p-button-lg">
        <i class="pi pi-arrow-circle-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>

      <Button
        label="Imprimir"
        icon="pi pi-file-pdf"
        class="p-button-danger p-button-lg"
        @click="imprimirDetalle()"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      />
    </div>
  </div>
</template>
<script>
import PagoProveedorService from "@/service/PagoProveedorService.js";
export default {
  name: "PagosProveedorDetalle",
  data() {
    return {
      detalle_pagos: [],
      detalle_pago: {},
      pago_proveedor: {},
      imprimiendo: false,
    };
  },

  pagoProveedorService: null,

  created() {
    this.pagoProveedorService = new PagoProveedorService();
  },

  computed: {
    total_pagado() {
      return this.convertirNumeroGermanicFormat(this.sumarMontoTotal());
    },
    saldo_pago() {
      return this.convertirNumeroGermanicFormat(
        this.pago_proveedor.monto_total - this.sumarMontoTotal()
      );
    },
  },
  mounted() {
    this.getDetallePagos();
    this.sumarMontoTotal();
  },

  methods: {
    imprimirDetalle() {
      this.imprimiendo = true;
      this.pagoProveedorService
        .imprimirDetalle(this.$route.params.id)
        .then(() => {
          this.imprimiendo = false;
        })
        .catch(() => {
          this.imprimiendo = false;
        });
    },
    sumarMontoTotal() {
      let total = 0;
      this.detalle_pagos.forEach((detalle_pago) => {
        total +=
          parseFloat(detalle_pago.monto) +
          parseFloat(detalle_pago.a_cuenta_metodo_pago);
      });
      return total;
    },
    goBack() {
      this.$router.go(-1);
    },
    getDetallePagos() {
      this.pagoProveedorService
        .getDetallePagos(this.$route.params.id)
        .then((data) => {
          this.detalle_pagos = data.detalle_pagos;
          this.detalle_pago = data.detalle_pagos[0] || {};
          this.pago_proveedor = data.pago_proveedor;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>
